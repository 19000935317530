<template>
  <base-section
    id="features"
    style="background: linear-gradient(200deg,#bca65e, #ed9f00);"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-factory',
          title: 'ENGINEERING DESIGN',
          text: '',
        },
        {
          icon: 'mdi-earth',
          title: 'PROCESS TECHNOLOGY',
          text: '',
        },
        {
          icon: 'mdi-flask-outline',
          title: 'MANUFACTURING TECHNOLOGY',
          text: '',
        },
      ],
    }),
  }
</script>
